<template>
	<div>

	</div>
</template>

<script>
export default {

	props: ['body', 'item_search', 'max', 'is_add', 'top']
	, data: function() {
		return {
			before: 0
			, scrollBody: ''
			, type: ''
			, is_scroll: false
		}
	}

	, computed: {
		page: function(){

			let t = this.item_search.page_number

			if(this.type){
				t = t - 1 >= 1 ? t - 1 : 1
			}else{
				t = t + 1
			}

			return t
		}
	}
	, methods: {
		init: function () {
			window.addEventListener('scroll', this.scrollListen);
			this.scrollBody = this.body
			// console.log('on init', this.scrollBody, this.is_add, this.max, this.top)
		}
		, scrollListen: function () {

			if(!this.is_add){
				return false
			}

			let w = window.innerHeight
			let b = this.scrollBody.scrollHeight
			let t = window.scrollY

			if(this.max){
				return false
			}

			let max = b - w
			if (max <= 200) {
				max = 200
			}

			if (t >= max) {
				// console.log(`w: ${w}, b: ${b}, m: ${max}, t: ${t}, type: ${this.type}, this.page : ${ this.item_search.page_number }`)

				this.removeScroll()
				this.$emit('change', this.item_search.page_number + 1)
				setTimeout(() => {
					this.init()
				}, 1000)
			}

		}
		, removeScroll: function () {
			console.log('scrollpagination removeScroll')
			this.is_scroll = false
			document.body.removeEventListener('scroll', this.scrollListen)
		}
	}
	, created() {
		console.log('on scroll pagination !!', this.item_search.page_number)
		this.init()
	}
}

</script>